define('ember-data-slug/mixins/store-slug-support', ['exports', 'ember'], function (exports, _ember) {
  function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

  var Map = _ember['default'].Map;
  var MapWithDefault = _ember['default'].MapWithDefault;
  var Mixin = _ember['default'].Mixin;
  var get = _ember['default'].get;
  exports['default'] = Mixin.create({

    init: function init() {
      this._super.apply(this, arguments);

      this._typeSlugCache = MapWithDefault.create({
        defaultValue: function defaultValue() {
          return Map.create();
        }
      });
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this._typeSlugCache.clear();
      delete this._typeSlugCache;
    },

    findRecord: function findRecord(modelName, idOrSlug, options) {
      var slugCache = this._typeSlugCache.get(modelName);

      var coercedIdOrSlug = '' + idOrSlug;

      var id = slugCache.get(coercedIdOrSlug);
      if (id) {
        return this._super(modelName, id, options);
      }

      // now we know we're dealing with a slug
      var slug = coercedIdOrSlug;

      // get the passed query without reload and backgroundReload, since those
      // are not supported by queryRecord and would end up as query params

      var _ref = options || {};

      var reload = _ref.reload;
      var backgroundReload = _ref.backgroundReload;

      var query = _objectWithoutProperties(_ref, ['reload', 'backgroundReload']);

      // eslint-disable-line no-unused-vars

      // add the slug to the query, so the adapter can use it to build the URL
      // for queryRecord
      query.__ember_data_slug = slug;

      return this.queryRecord(modelName, query).then(function (foundRecord) {
        id = get(foundRecord, 'id');

        // inform cache about slug -> id mapping
        slugCache.set(slug, id);

        // setup id -> id mapping, so the original findRecord behaviour is
        // maintained: once a record found via the slug is in the store and the
        // record is requested again via store.findRecord("modelName", id) if we
        // don't setup the id -> id mapping here, then the above `if (id)` path
        // is never taken and queryRecord is always used
        slugCache.set(id, id);

        return foundRecord;
      });
    }

  });
});