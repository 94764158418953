define('ember-data-slug/initializers/ember-data-slug', ['exports', 'ember-data', 'ember-data-slug'], function (exports, _emberData, _emberDataSlug) {
  exports.initialize = initialize;
  var Store = _emberData['default'].Store;
  var RESTAdapter = _emberData['default'].RESTAdapter;

  function initialize() /* application */{
    Store.reopen(_emberDataSlug.StoreSlugSupport);
    RESTAdapter.reopen(_emberDataSlug.AdapterSlugSupport);
  }

  exports['default'] = {
    name: 'ember-data-slug',
    initialize: initialize
  };
});