define('ember-data-slug/index', ['exports', 'ember-data-slug/mixins/store-slug-support', 'ember-data-slug/mixins/adapter-slug-support'], function (exports, _emberDataSlugMixinsStoreSlugSupport, _emberDataSlugMixinsAdapterSlugSupport) {
  Object.defineProperty(exports, 'StoreSlugSupport', {
    enumerable: true,
    get: function get() {
      return _emberDataSlugMixinsStoreSlugSupport['default'];
    }
  });
  Object.defineProperty(exports, 'AdapterSlugSupport', {
    enumerable: true,
    get: function get() {
      return _emberDataSlugMixinsAdapterSlugSupport['default'];
    }
  });
});