define('ember-data-slug/mixins/adapter-slug-support', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({

    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var __ember_data_slug = query.__ember_data_slug;

      if (__ember_data_slug) {
        return this.urlForFindRecord(__ember_data_slug, modelName);
      }

      return this._super.apply(this, arguments);
    },

    // sortQueryParams needs to be used, as there is no other way to modify query
    // params. There will be once `ds-improved-ajax` is enabled, wich will allow
    // us to overwrite dataForRequest instead
    sortQueryParams: function sortQueryParams(queryParams) {
      var __ember_data_slug = queryParams.__ember_data_slug;

      if (__ember_data_slug) {
        delete queryParams.__ember_data_slug;
      }

      return this._super.apply(this, arguments);
    }

  });
});